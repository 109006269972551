import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import DefaultLayout from '../components/default-layout/default-layout';
import ContentWrapper from '../components/content-wrapper/content-wrapper';
import SEO from '../components/seo/seo';
import WYSIWYG from '../components/wysiwyg/wysiwyg';

import styles from '../components/content-display/content-display.module.scss';

const Memberships = ({ data }) => {

  const content = data.nodePage;
  // Reformat the metatag data from Drupal in a way we can use.
  const metatags = content.metatag.reduce((accumulator, currentVal) => {
    return {
      ...accumulator,
      [currentVal.attributes.name]: currentVal.attributes.content
    };
  }, {});

  return (
    <DefaultLayout data={data}>
      <SEO
        {...metatags}
        pageUrl={content.path.alias}
        siteConfig={data.site.siteMetadata}
      />
      <ContentWrapper>
        <h1 className={styles.title}>{content.title}</h1>
        <WYSIWYG>
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{ __html: content.body.processed }}
          />
        </WYSIWYG>
        <iframe
          title='Memberships'
          name='SpektrixIFrame'
          id='SpektrixIFrame'
          frameBorder='0'
          onLoad={(event)=> event.target.className = 'loaded'}
          src={`${ data.site.siteMetadata.spektrixAPI }/${ data.site.siteMetadata.spektrixClientName }/website/Memberships.aspx?resize=true`}>
        </iframe>
      </ContentWrapper>
    </DefaultLayout>
  )
}

Memberships.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query MembershipsQuery {
    site {
      siteMetadata {
        title
        siteUrl
        spektrixClientName
        spektrixAPI
      }
    }
    nodePage(path: {alias: {eq: "/memberships"}}) {
      title
      drupal_id
      path {
        alias
      }
      body {
        processed
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
    }
  }
`;

export default Memberships;
